// extracted by mini-css-extract-plugin
export var carouselContainer = "J_v3 d_w d_H d_bf d_Z";
export var carouselContainerCards = "J_v4 J_v3 d_w d_H d_bf d_Z";
export var carouselContainerSides = "J_v5 d_w d_H d_Z";
export var prevCarouselItem = "J_v6 d_w d_H d_0 d_k";
export var prevCarouselItemL = "J_v7 J_v6 d_w d_H d_0 d_k";
export var moveInFromLeft = "J_v8";
export var prevCarouselItemR = "J_v9 J_v6 d_w d_H d_0 d_k";
export var moveInFromRight = "J_wb";
export var selectedCarouselItem = "J_wc d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "J_wd J_wc d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "J_wf J_wc d_w d_H d_Z d_bf";
export var nextCarouselItem = "J_wg d_w d_H d_0 d_k";
export var nextCarouselItemL = "J_wh J_wg d_w d_H d_0 d_k";
export var nextCarouselItemR = "J_wj J_wg d_w d_H d_0 d_k";
export var arrowContainer = "J_wk d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "J_wl J_wk d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "J_wm J_wl J_wk d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "J_wn J_wk d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "J_wp";
export var nextArrowContainerHidden = "J_wq J_wn J_wk d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "J_kG d_0";
export var prevArrow = "J_wr J_kG d_0";
export var nextArrow = "J_ws J_kG d_0";
export var carouselIndicatorContainer = "J_wt d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "J_wv d_w d_bz d_bF";
export var carouselText = "J_ww d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "J_wx J_ww d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "J_wy d_b7";
export var carouselIndicator = "J_wz J_wy d_b7";
export var carouselIndicatorSelected = "J_wB J_wy d_b7";
export var arrowsContainerTopRight = "J_wC d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "J_wD d_0 d_bl d_bC";
export var arrowsContainerSides = "J_wF d_0 d_bl d_bC";
export var smallArrowsBase = "J_wG d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "J_wH J_wG d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "J_wJ J_wH J_wG d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "J_wK J_wG d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "J_wL J_wK J_wG d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "J_p9";
export var iconSmall = "J_wM";
export var multipleWrapper = "J_wN d_bC d_bF d_bf";
export var multipleImage = "J_wP d_bC";
export var sidesPrevContainer = "J_wQ J_wH J_wG d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "J_wR J_wH J_wG d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";