// extracted by mini-css-extract-plugin
export var lbContainerOuter = "G_vN";
export var lbContainerInner = "G_vP";
export var movingForwards = "G_vQ";
export var movingForwardsOther = "G_vR";
export var movingBackwards = "G_vS";
export var movingBackwardsOther = "G_vT";
export var lbImage = "G_vV";
export var lbOtherImage = "G_vW";
export var prevButton = "G_vX";
export var nextButton = "G_vY";
export var closing = "G_vZ";
export var appear = "G_v0";