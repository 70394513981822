// extracted by mini-css-extract-plugin
export var alignDiscLeft = "t_th d_fp d_bG d_dv";
export var alignLeft = "t_qD d_fp d_bG d_dv";
export var alignDiscCenter = "t_tj d_fq d_bD d_dw";
export var alignCenter = "t_bP d_fq d_bD d_dw";
export var alignDiscRight = "t_tk d_fr d_bH d_dx";
export var alignRight = "t_qF d_fr d_bH d_dx";
export var footerContainer = "t_tl d_dW d_bW";
export var footerContainerFull = "t_tm d_dT d_bW";
export var footerHeader = "t_kf d_kf";
export var footerTextWrapper = "t_tn d_w";
export var footerDisclaimerWrapper = "t_km d_km d_ck";
export var badgeWrapper = "t_tp d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "t_tq d_bz d_bJ d_bN d_bL";
export var wide = "t_tr d_cy";
export var right = "t_ts d_bK";
export var line = "t_fk d_fl d_cv";
export var badgeDisclaimer = "t_tt d_bC d_bP d_bJ";
export var badgeContainer = "t_tv d_bz d_bH d_bP";
export var badge = "t_tw";
export var padding = "t_tx d_c7";
export var end = "t_ty d_bH";
export var linkWrapper = "t_tz d_dB";
export var link = "t_mC d_dB";
export var colWrapper = "t_tB d_cx";
export var consent = "t_f d_f d_bC d_bP";
export var disclaimer = "t_tC d_bz d_bJ";
export var media = "t_tD d_bx d_dy";
export var itemRight = "t_tF";
export var itemLeft = "t_tG";
export var itemCenter = "t_tH";
export var exceptionWeight = "t_qL q_rd";