// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_tJ d_gv d_cs";
export var heroHeaderCenter = "v_gw d_gw d_cs d_dw";
export var heroHeaderRight = "v_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "v_tK d_gr d_cw";
export var heroParagraphCenter = "v_gs d_gs d_cw d_dw";
export var heroParagraphRight = "v_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "v_tL d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "v_tM d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "v_tN d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "v_tP d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "v_tQ d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "v_rC q_rC";
export var heroExceptionNormal = "v_rD q_rD";
export var heroExceptionLarge = "v_rF q_rF";
export var Title1Small = "v_rh q_rh q_qN q_qP";
export var Title1Normal = "v_rj q_rj q_qN q_qQ";
export var Title1Large = "v_rk q_rk q_qN q_qR";
export var BodySmall = "v_rw q_rw q_qN q_q5";
export var BodyNormal = "v_rx q_rx q_qN q_q6";
export var BodyLarge = "v_ry q_ry q_qN q_q7";