// extracted by mini-css-extract-plugin
export var alignLeft = "B_qD d_fp d_bG d_dv";
export var alignCenter = "B_bP d_fq d_bD d_dw";
export var alignRight = "B_qF d_fr d_bH d_dx";
export var testimonialsContainer = "B_vx d_dW";
export var testimonialsContainerFull = "B_vy d_dT";
export var testimonialsMainHeader = "B_jw d_jw d_w d_cw";
export var testimonialsComponentParagraph = "B_jy d_jy";
export var testimonialsComponentSmall = "B_jx d_jx";
export var testimonialsComponentsWrapper = "B_js d_js d_y d_w d_cv d_b3 d_b5";
export var testimonialsComponentsWrapperDesign2 = "B_jt d_jt d_y d_w d_cv d_b3 d_b5 d_bC";
export var testimonialsComponentsWrapperDesign3 = "B_jv d_jv d_y d_w d_H d_b5 d_bT d_bz d_bJ d_bP d_bD";
export var testimonialsBackgroundColor = "B_vz d_d7";
export var colEqualHeight = "B_vB d_bz";
export var testimonialsColumnWrapper = "B_jz d_jz d_b3";
export var testimonialsImageWrapper = "B_jp d_jp d_w d_Z";
export var testimonialsImageWrapperDesign2 = "B_jq d_jq d_r d_Z d_bx";
export var testimonialsImageWrapperDesign3 = "B_jr d_jr d_Z d_bz";
export var design2TextWrapper = "B_vf d_bC d_bP d_bJ";
export var design3 = "B_vC d_cD d_H";
export var imageDesign2 = "B_vD";
export var SmallSmall = "B_qz q_qz q_qN q_q8";
export var SmallNormal = "B_qB q_qB q_qN q_q9";
export var SmallLarge = "B_qC q_qC q_qN q_q6";
export var exceptionWeight = "B_qL q_rd";